<template>
  <div class="select-property-container">
    <CSDialog
      dialogTitle="选择物业费规则"
      :dialogVisible="editCompanyOwnerVisible"
      dialogWidth="1180px"
      @onClose="editCompanyOwnerVisible = false"
      @onConfirm="saveRule"
    >
      <div slot="dialog-content" class="wrapper-container">
        <CSTable class="wrappers">
          <template v-slot:thead>
            <tr class="tr-header">
              <th style="border-radius: 8px 0px 0px 0px; width: 50px;border-left:none"></th>
              <th>创建时间</th>
              <th>规则名称</th>
              <th>计费标准</th>
              <th style="border-radius: 0px 8px 0px 0px; border-right: none" class="tuoqina">拖欠处理期限</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, index) in rulesList" :key="item.id">
              <td style="width: 50px">
                <label
                  style="margin-left: 5px; cursor: pointer"
                  @click="selectRule(index)"
                >
                  <div
                    :class="editIndex === index ? 'class-b' : 'class-a'"
                  ></div>
                </label>
              </td>
              <td>{{ item.createTime }}</td>
              <td>{{ item.rulesName }}</td>
              <td>{{ item.amount }}元/平米/月</td>
              <td>{{ item.delinquentDate }}个月</td>
            </tr>
          </template>
        </CSTable>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSTable from "@/components/common/CSTable";
import { queryRulesUrl } from "@/requestUrl";
export default {
  data() {
    return {
      rulesList: [],
      editCompanyOwnerVisible: false,
      editIndex: 0,
    };
  },
  components: {
    CSDialog,
    CSTable,
  },
  methods: {
    queryRule(pageN = 1, pageS = 10000) {
      this.$fly
        .post(queryRulesUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          pageSize: pageS, //页面大小
          pageNo: pageN, //页码
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.rulesList = res.data.datas;
        });
    },
    //保存物业费规则
    saveRule() {
      this.$vc.emit(this.$route.path, "selectPropertyFeeRules", {
        value: this.rulesList[this.editIndex],
      });
      this.editCompanyOwnerVisible = false;
    },
    //选择规则
    selectRule(index) {
      this.editIndex = index;
    },
  },
  mounted() {
    this.$vc.on(this.$route.path, "SelectPropertyFeeRules", (item) => {
      this.editCompanyOwnerVisible = true;
    });
    this.queryRule();
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  border-collapse: separate;
  border-radius: 8px 8px 0px 0px;
  padding: 0;
  margin: -1px 0 0 0px;
  padding: 8px 0 0 0 0;
  height: 100px;
}
.wrapper-container {
  padding: 30px 28px 0 28px;
  font-size: 20px;
}
th {
  font-weight: normal;
  text-align: center;
  border: 1px solid #fff;
  height: 48px;
}
tr{
  width: 10px !important;
}
td {
  border: 1px solid #20b69c !important;
  z-index: 1;
  text-align: center;
  font-weight: 400;
  height: 60px;
}
.tr-header {
  color: #ffffff;
  background-color: #20b69c;
  margin: 0 !important;
}
.wrappers {
  overflow-y: scroll;
  height: 240px;
  padding-right: 6px;
}
.class-a {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../assets/icon5.png");
}

.class-b {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../assets/icon3.png");
}

</style>